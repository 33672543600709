import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocalOffer from "@mui/icons-material/LocalOffer";
import {
  CardContent,
  Chip,
  Collapse,
  IconButton,
  Typography as MuiTypography,
  Skeleton,
} from "@mui/material";
import React from "react";
import styled from "styled-components/macro";

const Typography = styled(MuiTypography)`
  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

const CollapseIconButton = styled(IconButton)`
  height: 10px;
  width: 10px;
  margin-top: 2px;
  margin-right: 5px;
  margin-left: -8px;
`;

const CollapseCardContent = styled(CardContent)`
  height: 250px;
  padding: inherit;
`;

const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 6%;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-right: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
`;

const Value = styled.h1`
  width: 100%;
  text-align: end;
  // height: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  // line-height: 32px;
  // color: #0f172a;
  padding-top: 5px;
  margin-top: -2px;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 0px;
  text-decoration: ${(p) => (p.strike ? "line-through" : "")};
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: #f1f5f9;
  margin-bottom: 16px;
`;
// height: 24px;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  text-decoration: ${(p) => (p.strike ? "line-through" : "")};
`;

const SubTitle = styled.h3`
  @media (max-width: 1200px) {
    font-size: 12px;
  }
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #64748b;
  padding: 0px;
  margin: 0px;
  margin-right: 2px;
  line-height: 22px;
`;

const TotalValue = styled.h3`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: right;
  color: #0f172a;
  padding: 0px;
  margin: 0px;
  margin-left: 3px;
`;

const ExtendedSummaryCard = ({
  title,
  valueUnits,
  valueProfit,
  valueSales,
  valuePPC,
  subTitleOne,
  totalValueOne,
  subTitleTwo,
  totalValueTwo,
  showCollapseValueOne = false,
  valueOneExpended = "",
  isLoading = false,
  setDisplayBreakdown = null,
  displayBreakdown = false,
  hasDeals = false,
}) => {
  const handleToggle = () => {
    setDisplayBreakdown(!displayBreakdown);
  };

  const getLogo = (hasDeals) => {
    if (hasDeals) {
      return (
        <LocalOffer
          sx={{
            marginRight: 1,
            color: "#797bea",
            fontSize: 20,
            opacity: 0.5,
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getTitleWeekDay = (relativeDate) => {
    const dateRange = getTitleDateRange(relativeDate);
    // if one date return its day of the week else return none
    if (!dateRange.includes("-")) {
      const [day, month, year] = dateRange.split("/").map(Number);
      const date = new Date(year, month - 1, day);
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return days[date.getDay()];
    } else {
      return null;
    }
  };

  const getTitleDateRange = (relativeDate) => {
    const today = new Date();
    let startDate, endDate;

    const lowerCaseDate = relativeDate.toLowerCase();

    if (lowerCaseDate.includes("today")) {
      startDate = endDate = today;
    } else if (lowerCaseDate.includes("yesterday")) {
      startDate = endDate = new Date(today);
      startDate.setDate(today.getDate() - 1);
    } else if (lowerCaseDate.includes("days ago")) {
      const daysAgo = parseInt(lowerCaseDate.replace(" days ago", ""));
      startDate = endDate = new Date(today);
      startDate.setDate(today.getDate() - daysAgo);
    } else if (lowerCaseDate.includes("7 days")) {
      endDate = new Date();
      endDate.setDate(new Date().getDate() - 1);
      startDate = new Date();
      startDate.setDate(new Date().getDate() - 7);
    } else if (lowerCaseDate.includes("30 days")) {
      endDate = new Date();
      endDate.setDate(new Date().getDate() - 1);
      startDate = new Date();
      startDate.setDate(new Date().getDate() - 30);
    } else if (lowerCaseDate.includes("month to date")) {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = today;
    } else if (lowerCaseDate.includes("this month")) {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = today;
    } else if (lowerCaseDate.includes("last month")) {
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0);
    } else {
      return ""; // Invalid relative date
    }

    if (startDate.getTime() === endDate.getTime()) {
      return formatDate(startDate);
    } else {
      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }
  };

  return (
    <Card>
      <div clsssName="card-content">
        <div
          clsssName="card-timeframe"
          style={{
            flex: 1,
            minWidth: 250,
          }}
        >
          <Header>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: 16,
                padding: 1,
                color: "#0F172A",
                marginRight: 1,
              }}
            >
              {title}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              {getLogo(hasDeals)}
            </div>
          </Header>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontWeight: 300,
              fontSize: 12,
              padding: 1,
              color: "#0F172A",
              marginRight: 1,
              marginTop: -3,
              maxWidth: 100,
            }}
          >
            {getTitleDateRange(title)}
            <Chip
              label={getTitleWeekDay(title)}
              sx={{
                fontSize: "0.7rem",
                height: 15,
                marginLeft: 2,
                marginTop: -1,
                display: getTitleWeekDay(title) ? "inline-flex" : "none",
              }}
            />
          </Typography>
        </div>
        <div
          className="card-data"
          style={{
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#6C92FF",
              height: 30,
            }}
          >
            Units:
            <Value>
              {!isLoading ? (
                valueUnits
              ) : (
                <Skeleton variant="rectangular" height={15} />
              )}
            </Value>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#83D475",
              height: 30,
            }}
          >
            Profit:
            <Value>
              {!isLoading ? (
                valueProfit
              ) : (
                <Skeleton variant="rectangular" height={15} />
              )}
            </Value>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#FFD580",
              height: 30,
            }}
          >
            Sales:
            <Value>
              {!isLoading ? (
                valueSales
              ) : (
                <Skeleton variant="rectangular" height={15} />
              )}
            </Value>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#FF9494",
              height: 30,
            }}
          >
            PPC:
            <Value>
              {!isLoading ? (
                valuePPC
              ) : (
                <Skeleton variant="rectangular" height={15} />
              )}
            </Value>
          </div>
        </div>
      </div>
      <HorizontalLine />
      {showCollapseValueOne && !isLoading ? (
        <Collapse in={displayBreakdown} timeout="auto" unmountOnExit>
          <CollapseCardContent
            dangerouslySetInnerHTML={{ __html: valueOneExpended }}
          />
        </Collapse>
      ) : (
        <div />
      )}
      <Footer>
        {showCollapseValueOne ? (
          <CollapseIconButton
            aria-label="expand"
            size="medium"
            onClick={() => handleToggle()}
          >
            {displayBreakdown ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </CollapseIconButton>
        ) : (
          <div />
        )}
        <SubTitle>{subTitleOne}</SubTitle>
        {isLoading ? (
          <Skeleton variant="rectangular" width={80} height={15} />
        ) : (
          <TotalValue>{totalValueOne}</TotalValue>
        )}
      </Footer>
      <Footer>
        <SubTitle>{subTitleTwo}</SubTitle>
        {isLoading ? (
          <Skeleton variant="rectangular" width={80} height={15} />
        ) : (
          <TotalValue>{totalValueTwo}</TotalValue>
        )}
      </Footer>
    </Card>
  );
};

export default ExtendedSummaryCard;
