import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckboxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { atomicConfig } from "../../../../../config";
import { createAsinTrackingData } from "../../../../../redux/slices/product_detail";
import { call_api_auth } from "../../../../../utils/services";

const FetchCompetButton = styled(Button)`
  background-color: #f1f8ff !important;
  color: #3f97fe;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #f1f8ff;
`;

const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  border-top: 1px solid #e0e0e0;
`;

const SaveButton = styled(Button)`
  font-size: 14px;
  font-family: "Inter", sans-serif;
  border-radius: 5px;
  background-color: #3eb0fd;
  padding: 5px 10px;
`;

const ProductCard = styled(Card)`
  display: flex;
  margin: 10px 0;
  padding: 12px;
  width: 100%;
  position: relative;
  border: ${(props) =>
    props.isSelected ? "3px solid black" : "1px solid #e0e0e0"};
  transition: all 0.2s ease;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ProductImageContainer = styled.div`
  flex: 0 0 120px;
  margin-right: 16px;
`;

const ProductImage = styled.img`
  width: 120px;
  height: 120px;
  object-fit: contain;
`;

const ProductDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
`;

const ProductTitle = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  color: #0f1111;
  max-width: 95%;

  &:hover {
    color: #007185;
    text-decoration: underline;
  }
`;

const ProductMetadata = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 8px 0;
`;

const ProductPrice = styled(Typography)`
  color: #b12704;
  font-size: 18px;
  font-weight: bold;
`;

const RatingsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RatingsCount = styled.span`
  color: #007185;
  margin-left: 5px;
  font-size: 14px;
`;

const ActionButton = styled(IconButton)`
  position: absolute;
  right: 12px;
  top: 12px;
`;

const ProductInfo = styled.div`
  color: #565959;
  font-size: 13px;
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const CompetitorsInput = ({ store, atomicAsin }) => {
  const dispatch = useDispatch();
  const [competitors, setCompetitors] = useState([]);
  const [selectedCompetitors, setSelectedCompetitors] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleFetchCompetitors = async () => {
    setLoading(true);
    try {
      const url = `${atomicConfig.storeManagementServiceUrl}/competitors/${store}?asin=${atomicAsin}`;
      const result = await call_api_auth(url, "GET");

      if (result && result.data) {
        setCompetitors(result.data);
        setOpen(true);
      } else {
        console.error("Error fetching competitors: No data returned");
      }
    } catch (error) {
      console.error("Error:", error.message || "Something went wrong");
    }
    setLoading(false);
  };

  const handleToggleCompetitor = (asin) => {
    setSelectedCompetitors((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(asin)) {
        newSet.delete(asin);
      } else {
        newSet.add(asin);
      }
      return newSet;
    });
  };

  const handleSave = () => {
    const competitorsToAdd = Array.from(selectedCompetitors);
    competitorsToAdd.forEach((asin) => {
      dispatch(
        createAsinTrackingData({
          asin,
          atomicAsin,
          store,
        })
      );
    });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <FetchCompetButton onClick={handleFetchCompetitors} disabled={loading}>
        {loading ? "Fetching..." : "Fetch Top Competitors"}
      </FetchCompetButton>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Select Competitors</DialogTitle>

        <DialogContent>
          {competitors.map((competitor) => (
            <ProductCard
              key={competitor.asin}
              isSelected={selectedCompetitors.has(competitor.asin)}
            >
              <ProductImageContainer>
                <ProductImage src={competitor.image} alt={competitor.title} />
              </ProductImageContainer>

              <ProductDetails>
                <div>
                  <ProductTitle
                    onClick={() => window.open(competitor.link, "_blank")}
                  >
                    {competitor.title}
                  </ProductTitle>

                  <ProductMetadata>
                    <RatingsContainer>
                      <Rating
                        value={competitor.rating}
                        readOnly
                        precision={0.1}
                        size="small"
                      />
                      <RatingsCount>({competitor.ratings_total})</RatingsCount>
                    </RatingsContainer>

                    <ProductPrice>${competitor.price?.toFixed(2)}</ProductPrice>
                  </ProductMetadata>

                  <ProductInfo>
                    <span>ASIN: {competitor.asin}</span>
                    {competitor.is_prime && <span>• Prime Eligible</span>}
                    <span>• Position: {competitor.position}</span>
                  </ProductInfo>
                </div>
              </ProductDetails>

              <ActionButton
                onClick={() => handleToggleCompetitor(competitor.asin)}
                size="small"
              >
                {selectedCompetitors.has(competitor.asin) ? (
                  <CheckBoxIcon color="success" />
                ) : (
                  <CheckboxOutlineBlankIcon />
                )}
              </ActionButton>
            </ProductCard>
          ))}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <SaveButton
            variant="contained"
            onClick={handleSave}
            disabled={selectedCompetitors.size === 0}
          >
            Save {selectedCompetitors.size} Selected
          </SaveButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CompetitorsInput;
