import { Box, Grid } from "@mui/material";
import React from "react";

import ExtendedSummaryCard from "./ExtendedSummaryCard";

const Summaries = ({
  productSalesList,
  productProfitabilityList,
  isDataLoading,
}) => {
  const getDateStringIndex = (daysAgo) => {
    const now = new Date();
    now.setDate(now.getDate() - daysAgo);
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    return `${month}-${day}`;
  };

  const getTotalProfit = (daysAgo) => {
    return Math.round(getTotalDailyProfit(daysAgo)).toLocaleString() + "$";
  };

  const profitPartPerUnit = (part) => {
    // Calculate the average extra fees per day
    if (
      productProfitabilityList === undefined ||
      productProfitabilityList === null ||
      Object.keys(productProfitabilityList).length === 0
    ) {
      return 0;
    } else {
      const totalProfitPart = Object.values(productProfitabilityList).reduce(
        (accAsin, currAsin) => {
          return (
            accAsin +
            Object.values(currAsin).reduce((accDay, currDay) => {
              return accDay + currDay[part];
            }, 0)
          );
        },
        0
      );

      const totalUnitsSold = Object.values(productSalesList).reduce(
        (accAsin1, currAsin1) =>
          accAsin1 +
          currAsin1["units"].reduce((accDay, currDay) => accDay + currDay, 0),
        0
      );
      // totalUnitsSold - is the profit value somehow ?????? BOAZ
      const profitPartPerUnit = totalProfitPart / totalUnitsSold;
      return profitPartPerUnit;
    }
  };

  const unitsSoldPerPeriod = (period) => {
    if (!productSalesList || Object.keys(productSalesList).length === 0) {
      return 0;
    }

    const dates = Object.values(productSalesList)[0]["dates"];
    let relevantIndexPeriod = [];

    if (typeof period === "string") {
      relevantIndexPeriod = [dates.indexOf(period)];
    } else if (typeof period === "object") {
      relevantIndexPeriod = period;
      if (period.length > 7) {
        relevantIndexPeriod.pop();
        relevantIndexPeriod.push(0);
      }
      relevantIndexPeriod = relevantIndexPeriod.map(
        (i) => dates.length - 1 - i
      );
    } else {
      relevantIndexPeriod = [dates.length - 1 - period];
    }

    return Object.values(productSalesList).reduce((accAsin, currAsin) => {
      return (
        accAsin +
        relevantIndexPeriod.reduce(
          (accDay, day) => accDay + (currAsin["units"][day] || 0),
          0
        )
      );
    }, 0);
  };

  const getTotalDailyProfit = (daysAgo) => {
    const currDateString = getDateStringIndex(daysAgo);
    if (productProfitabilityList) {
      const dayExtras =
        profitPartPerUnit("extra_fee") * unitsSoldPerPeriod(currDateString);
      const dayRefunds =
        profitPartPerUnit("refunded_amount") *
        unitsSoldPerPeriod(currDateString);

      const otherProfitParts = Object.values(productProfitabilityList).reduce(
        (acc, curr) => {
          const dayValues = curr[currDateString];
          const sumDaily = dayValues
            ? dayValues["orderedProductSales"] +
              //dayValues["extra_fee"] +
              Object.entries(dayValues)
                .filter(
                  ([k]) =>
                    k !== "asin" &&
                    k !== "orderedProductSales" &&
                    k !== "extra_fee" &&
                    k !== "refunded_amount"
                )
                .reduce((acc, [, val]) => acc + val * -1, 0)
            : 0;
          return acc + sumDaily;
        },
        0
      );
      return otherProfitParts - dayRefunds + dayExtras;
    } else {
      return 0;
    }
  };

  const getTotalMargin = (daysAgo) => {
    const sales = getTotalDailyValue(daysAgo, "sales");
    const profit = getTotalDailyProfit(daysAgo);
    return Math.round((profit / sales) * 100).toLocaleString() + "%" || 0;
  };

  const getTotalDailyProfitElement = (daysAgo, element) => {
    const currDateString = getDateStringIndex(daysAgo);
    return productProfitabilityList
      ? Object.values(productProfitabilityList).reduce((acc, curr) => {
          const dayValues = curr[currDateString];
          return acc + (dayValues ? dayValues[element] : 0);
        }, 0)
      : 0;
  };

  const getTotalProfitElement = (daysAgo, element) => {
    const value = getTotalDailyProfitElement(daysAgo, element);
    if (element === "extra_fee") {
      return (value >= 0 ? "+" : "") + Math.round(value).toLocaleString() + "$";
    } else {
      return Math.round(value * -1).toLocaleString() + "$";
    }
  };

  const getTotalDailyValue = (daysAgo, metric) => {
    return productSalesList
      ? Object.values(productSalesList).reduce(
          (acc, curr) =>
            acc +
            (metric === "deals"
              ? curr[metric][curr[metric].length - 1 - daysAgo] !== ""
                ? 1
                : 0
              : curr[metric][curr[metric].length - 1 - daysAgo] || 0),
          0
        )
      : 0;
  };

  const getTotalString = (value, metric) => {
    if (metric === "units") {
      return Math.round(value).toLocaleString();
    } else if (metric === "sales") {
      return Math.round(value).toLocaleString() + "$";
    } else if (metric === "ppc") {
      return (Math.round(value) * -1).toLocaleString() + "$";
    } else if (metric === "deals") {
      return value;
    } else {
      return 0;
    }
  };

  const getTotalDaily = (daysAgo, metric) => {
    const value = getTotalDailyValue(daysAgo, metric);
    return getTotalString(value, metric);
  };

  const getTotalForPeriod = (metric, days, includeLastDay = false) => {
    let totalMetric = 0;
    let lastDay = includeLastDay ? 0 : 1;
    days = includeLastDay ? days - 1 : days;
    for (let i = lastDay; i <= days; i++) {
      totalMetric += getTotalDailyValue(i, metric);
    }
    return getTotalString(totalMetric, metric);
  };

  const getTotalProfitForPeriod = (days, includeLastDay = false) => {
    let totalProfit = 0;
    let lastDay = includeLastDay ? 0 : 1;
    days = includeLastDay ? days - 1 : days;
    for (let i = lastDay; i <= days; i++) {
      totalProfit += getTotalDailyProfit(i);
    }
    return Math.round(totalProfit).toLocaleString() + "$";
  };

  const getTotalProfitElementForPeriod = (
    days,
    element,
    includeLastDay = false
  ) => {
    let totalElement = 0;
    let lastDay = includeLastDay ? 0 : 1;
    days = includeLastDay ? days - 1 : days;
    for (let i = lastDay; i <= days; i++) {
      totalElement += getTotalDailyProfitElement(i, element);
    }
    if (element === "extra_fee") {
      return (
        (totalElement >= 0 ? "+" : "") +
        Math.round(totalElement).toLocaleString() +
        "$"
      );
    } else {
      return Math.round(totalElement * -1).toLocaleString() + "$";
    }
  };

  const getTotalMarginForPeriod = (days, includeLastDay = false) => {
    let totalSales = 0;
    let totalProfit = 0;
    let lastDay = includeLastDay ? 0 : 1;
    days = includeLastDay ? days - 1 : days;
    for (let i = lastDay; i <= days; i++) {
      totalSales += getTotalDailyValue(i, "sales");
      totalProfit += getTotalDailyProfit(i);
    }
    return Math.round((totalProfit / totalSales) * 100).toLocaleString() + "%";
  };

  const getBreakdownHTMLstring = (period, func, includeLastDay) => {
    return `
      <div style="display: flex">
        <div style="width: 100%">Cogs: </div>
        <div>${func(period, "cogs_cost", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">Refunds (Smoothed): </div>
        <div>-${Math.round(
          profitPartPerUnit("refunded_amount") *
            unitsSoldPerPeriod(
              func === getTotalProfitElementForPeriod
                ? Array.from({ length: period }, (_, i) => i + 1)
                : period
            )
        ).toLocaleString()}$</div>
      </div>
      <div style="display: flex;font-style: italic;color: lightgrey;font-size: 0.8em">
        <div style="width: 100%">Refunds (actual): </div>
        <div>${func(period, "refunded_amount", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">Promotions: </div>
        <div>${func(period, "item_promotion_discount", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">Fees: </div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Fulfillments: </div>
        <div>${func(period, "fulfillment_fee", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Referrals: </div>
        <div>${func(period, "referral_fee", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Extras (Smoothed): </div>
        <div>${Math.round(
          profitPartPerUnit("extra_fee") *
            unitsSoldPerPeriod(
              func === getTotalProfitElementForPeriod
                ? Array.from({ length: period }, (_, i) => i + 1)
                : period
            )
        ).toLocaleString()}$</div>
      </div>
      <div style="display: flex;font-style: italic;color: lightgrey;font-size: 0.8em">
        <div style="width: 100%">- Extras (actual): </div>
        <div>${func(period, "extra_fee", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">PPC: </div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Product: </div>
        <div>${func(period, "product_cost", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Brand: </div>
        <div>${func(period, "brand_cost", includeLastDay)}</div>
      </div>
      <div style="display: flex">
        <div style="width: 100%">- Display: </div>
        <div>${func(period, "display_cost", includeLastDay)}</div>
      </div>
    `;
  };

  const getDaysPassedInMonth = () => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const diffTime = Math.abs(today - startOfMonth);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const daysPassedInMonth = getDaysPassedInMonth();
  const cardWidth = 4;

  const [displayBreakdown, setDisplayBreakdown] = React.useState(false);
  // const [refundsPerUnit] = React.useState(profitPartPerUnit("refunded_Amount"));
  // const [extrasPerUnit] = React.useState(profitPartPerUnit("extra_fee"));

  return (
    <Grid
      container
      spacing={1}
      style={{ "margin-bottom": "20px", "margin-top": "10px" }}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        overflow="none"
        whiteSpace="nowrap"
        p={2}
        style={{ width: "100%" }}
      >
        <Box
          display="flex"
          overflow="auto"
          whiteSpace="nowrap"
          p={2}
          style={{ width: "60%" }}
        >
          <Grid item xs={cardWidth}>
            <ExtendedSummaryCard
              title="Today"
              valueUnits={getTotalDaily(0, "units")}
              valueProfit={getTotalProfit(0)}
              valueSales={getTotalDaily(0, "sales")}
              valuePPC={getTotalDaily(0, "ppc")}
              subTitleOne="Breakdown"
              totalValueOne={" "}
              showCollapseValueOne={true}
              valueOneExpended={getBreakdownHTMLstring(
                0,
                getTotalProfitElement
              )}
              subTitleTwo="Net margin"
              totalValueTwo={getTotalMargin(0)}
              isLoading={isDataLoading}
              setDisplayBreakdown={setDisplayBreakdown}
              displayBreakdown={displayBreakdown}
              hasDeals={getTotalDaily(0, "deals")}
            />
          </Grid>
          <Grid item xs={cardWidth} style={{ marginLeft: "5px" }}>
            <ExtendedSummaryCard
              title="Yesterday"
              valueUnits={getTotalDaily(1, "units")}
              valueProfit={getTotalProfit(1)}
              valueSales={getTotalDaily(1, "sales")}
              valuePPC={getTotalDaily(1, "ppc")}
              subTitleOne="Breakdown"
              totalValueOne={" "}
              showCollapseValueOne={true}
              valueOneExpended={getBreakdownHTMLstring(
                1,
                getTotalProfitElement
              )}
              subTitleTwo="Net margin"
              totalValueTwo={getTotalMargin(1)}
              isLoading={isDataLoading}
              setDisplayBreakdown={setDisplayBreakdown}
              displayBreakdown={displayBreakdown}
              hasDeals={getTotalDaily(1, "deals")}
            />
          </Grid>
          {[...Array(29).keys()].map((i) => (
            <Grid item xs={cardWidth} style={{ marginLeft: "5px" }}>
              <ExtendedSummaryCard
                title={`${i + 2} days ago`}
                valueUnits={getTotalDaily(i + 2, "units")}
                valueProfit={getTotalProfit(i + 2)}
                valueSales={getTotalDaily(i + 2, "sales")}
                valuePPC={getTotalDaily(i + 2, "ppc")}
                subTitleOne="Breakdown"
                totalValueOne={" "}
                showCollapseValueOne={true}
                valueOneExpended={getBreakdownHTMLstring(
                  i + 2,
                  getTotalProfitElement
                )}
                subTitleTwo="Net margin"
                totalValueTwo={getTotalMargin(i + 2)}
                isLoading={isDataLoading}
                setDisplayBreakdown={setDisplayBreakdown}
                displayBreakdown={displayBreakdown}
                hasDeals={getTotalDaily(i + 2, "deals")}
              />
            </Grid>
          ))}
        </Box>
        <Box
          display="flex"
          overflow="none"
          whiteSpace="nowrap"
          p={2}
          style={{
            width: "40%",
            boxShadow: "-20px 0 20px -20px rgba(0,0,0,0.3)",
          }}
        >
          <Grid item xs={6}>
            <ExtendedSummaryCard
              title="7 days"
              valueUnits={getTotalForPeriod("units", 7)}
              valueProfit={getTotalProfitForPeriod(7)}
              valueSales={getTotalForPeriod("sales", 7)}
              valuePPC={getTotalForPeriod("ppc", 7)}
              subTitleOne="Breakdown"
              totalValueOne={" "}
              showCollapseValueOne={true}
              valueOneExpended={getBreakdownHTMLstring(
                7,
                getTotalProfitElementForPeriod,
                false
              )}
              subTitleTwo="Net margin"
              totalValueTwo={getTotalMarginForPeriod(7)}
              isLoading={isDataLoading}
              setDisplayBreakdown={setDisplayBreakdown}
              displayBreakdown={displayBreakdown}
            />
          </Grid>
          <Grid item xs={6} style={{ marginLeft: "5px" }}>
            <ExtendedSummaryCard
              title="Month to date"
              valueUnits={getTotalForPeriod("units", daysPassedInMonth, true)}
              valueProfit={getTotalProfitForPeriod(daysPassedInMonth, true)}
              valueSales={getTotalForPeriod("sales", daysPassedInMonth, true)}
              valuePPC={getTotalForPeriod("ppc", daysPassedInMonth, true)}
              subTitleOne="Breakdown"
              totalValueOne={" "}
              showCollapseValueOne={true}
              valueOneExpended={getBreakdownHTMLstring(
                daysPassedInMonth,
                getTotalProfitElementForPeriod,
                true
              )}
              subTitleTwo="Net margin"
              totalValueTwo={getTotalMarginForPeriod(daysPassedInMonth, true)}
              isLoading={isDataLoading}
              setDisplayBreakdown={setDisplayBreakdown}
              displayBreakdown={displayBreakdown}
            />
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default Summaries;
